export function setFractions(fullNumber) {
  const fullNumberFloor = Math.floor(fullNumber)
  const decimalNumber = fullNumber - fullNumberFloor

  if (decimalNumber > 0 && decimalNumber <= .03125) {
    return fullNumberFloor
  }
  if (decimalNumber > .0315 && decimalNumber <= .09375) {
    return `${fullNumberFloor} - 1/16`
  }
  if (decimalNumber > .09375 && decimalNumber <= .15625) {
    return `${fullNumberFloor} - 1/8`
  }
  if (decimalNumber > .15625 && decimalNumber <= .21875) {
    return `${fullNumberFloor} - 3/16`
  }
  if (decimalNumber > .21875 && decimalNumber <= .28125) {
    return `${fullNumberFloor} - 1/4`
  }
  if (decimalNumber > .28125 && decimalNumber <= .34375) {
    return `${fullNumberFloor} - 5/16`
  }
  if (decimalNumber > .34375 && decimalNumber <= .40625) {
    return `${fullNumberFloor} - 3/8`
  }
  if (decimalNumber > .40625 && decimalNumber <= .46875) {
    return `${fullNumberFloor} - 7/16`
  }
  if (decimalNumber > .46875 && decimalNumber <= .53125) {
    return `${fullNumberFloor} - 1/2`
  }
  if (decimalNumber > .53125 && decimalNumber <= .59375) {
    return `${fullNumberFloor} - 9/16`
  }
  if (decimalNumber > .59375 && decimalNumber <= .65625) {
    return `${fullNumberFloor} - 5/8`
  }
  if (decimalNumber > .65625 && decimalNumber <= .71875) {
    return `${fullNumberFloor} - 11/16`
  }
  if (decimalNumber > .71875 && decimalNumber <= .78125) {
    return `${fullNumberFloor} - 3/4`
  }
  if (decimalNumber > .78125 && decimalNumber <= .84375) {
    return `${fullNumberFloor} - 13/16`
  }
  if (decimalNumber > .84375 && decimalNumber <= .90625) {
    return `${fullNumberFloor} - 7/8`
  }
  if (decimalNumber > .90625 && decimalNumber <= .96875) {
    return `${fullNumberFloor} - 15/16`
  }
  if (decimalNumber > .96875 ) {
    return fullNumberFloor + 1
  }
  return fullNumber;
}
