import {Box, gridClasses, Typography} from "@mui/material";
import {DataGridPremium, GridToolbarContainer, GridToolbarExport, useGridApiRef} from "@mui/x-data-grid-premium";
import {useState} from "react";
import {setFractions} from "../utils/Fractions";

export function CutListDisplay(props) {
    const apiRef = useGridApiRef();
    const [sortModel, setSortModel] = useState([
        {
            field: "material",
            sort: "asc",
        },
        {
            field: "width",
            sort: "desc",
        },
        {
            field: "length",
            sort: "desc",
        },
        {
            field: "count",
            sort: "desc",
        }
    ])

    function valueFormatterFractions(params) {
        return setFractions(params.value)
    }

    const columns = [
        {field: 'material', headerName: 'Material', width: 125, editable: false, type: "string"},
        {field: 'count', headerName: 'Count', width: 100, editable: false, type: "number"},
        {
            field: 'length',
            headerName: 'Length',
            width: 100,
            editable: false,
            type: "number",
            valueFormatter: valueFormatterFractions
        },
        {
            field: 'width',
            headerName: 'Width',
            width: 100,
            editable: false,
            type: "number",
            valueFormatter: valueFormatterFractions
        },
        {field: 'description', headerName: 'Description', width: 250, editable: false, type: "string"},
    ];

    function EditToolbar() {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <Box
                    sx={{
                        display: "none",
                        displayPrint: "block",
                        textAlign: "center",
                        width: "100%",
                        pt: 2,
                        pb: 1,
                        pl: 1,
                    }}
                >
                    {props.clientName} || {props.workOrder} || {props.address} || {props.dueDate} || {props.modelName}
                </Box>
                <Box sx={{displayPrint: "none"}}>
                    <GridToolbarExport/>
                </Box>
            </GridToolbarContainer>
        );
    }


    return (
        <Box>
            <Typography variant="h4" gutterBottom component="div" mt={2} textAlign="center">
                Cut List
            </Typography>
            <Box sx={{width: "800px", mx: "auto", my: "25px"}}>
                <DataGridPremium
                    autoHeight
                    apiRef={apiRef}
                    editMode="row"
                    rows={props.totalCutList}
                    columns={columns}
                    density={"compact"}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    components={{
                        Toolbar: EditToolbar,
                    }}
                />
            </Box>

        </Box>
    )
}

