import {
    DataGridPremium,
    useGridApiRef,
    GridActionsCellItem,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid-premium';
import {useCallback, useEffect, useRef, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle, Paper,
    Stack,
    Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import {db, analytics} from "../../firebase-config";
import {
    collection,
    addDoc,
    serverTimestamp,
    Timestamp,
    doc,
    getDoc,
    updateDoc,
    deleteDoc,
    query, where,  onSnapshot
} from 'firebase/firestore'
import {getAuth} from "firebase/auth";
import {useSnackbar} from 'notistack';
import {useNavigate, useParams} from "react-router-dom";
import {setFractions} from "../../utils/Fractions";
import {CutListDisplay} from "../../components/CutListDisplay";
import {CommonForm} from "../../components/CommonForm";
import {logEvent} from "firebase/analytics";
import {handleCalculations} from "../../utils/CalculatorUtils";
import {getStorage, ref as storageRef, uploadBytesResumable} from "firebase/storage";

import {
    verandaStairSchema,
    deckStairSchema,
    deckLandingSchema,
    garageStairSchema,
    garageLandingSchema,
    interiorStairSchema,
    interiorWinderSchema
} from "../../utils/JoiValidations";
import {FilesGrid} from "../../components/FilesGrid";
import {v4} from "uuid";
import {useDialog, useFileDelete} from "../../hooks";
import {LumberOrderDisplay} from "../../components/LumberOrderDisplay";

const rows = [];

function CircularProgressWithLabel(props) {
    return (
        <Box sx={{position: 'relative', display: 'inline-flex'}}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}


export function CreateOrder({stairType}) {
    const apiRef = useGridApiRef();
    const [tableData, setTableData] = useState([...rows])
    const [clientName, setClientName] = useState("")
    const [workOrder, setWorkOrder] = useState("")
    const [address, setAddress] = useState("")
    const [dueDate, setDueDate] = useState("")
    const [modelName, setModelName] = useState("")
    const [stairsBuilt, setStairsBuilt] = useState(false)
    const [inProduction, setInProduction] = useState(false)
    const [stairsShipped, setStairsShipped] = useState(false)
    const [files, setFiles] = useState([])
    const [isFlush, setIsFlush] = useState(false)
    const [isFlushProcessing, setIsFlushProcessing] = useState(false)
    const [clone, setClone] = useState(false)
    const [totalCutList, setTotalCutList] = useState([])
    const [disableSubmit, setDisableSubmit] = useState(true)
    const [selectedFile, setSelectedFile] = useState(null);
    const [lumberOrder, setLumberOrder] = useState(null);
    const {isOpen, close, open} = useDialog();
    const fileIdToDelete = useRef();
    const [deleteFile, deleting] = useFileDelete();
    const [uploadPercentage, setUploadPercentage] = useState(0);
    // const [isLoadFilesLoading, setisLoadFilesLoading] = useState(false);


    const {enqueueSnackbar} = useSnackbar()

    const workOrdersCollectionRef = collection(db, "work_orders");
    const auth = getAuth();
    const user = auth.currentUser;
    const uid = user.uid;

    let params = useParams()
    let navigate = useNavigate()

    const zeroPad = (num, places) => String(num).padStart(places, '0')

    const processCuts = (rows) => {
        let allCuts = []
        // add all cuts from the order to a single-level array
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].cutList === undefined) return
            for (let j = 0; j < rows[i].cutList.length; j++) {
                if (rows[i].cutList[j].displayOnCutList === true) {
                    allCuts.push(rows[i].cutList[j])
                }
            }
        }
        // add index to each element for DataGridPremium
        let cutList = allCuts.map((row, idx) => {
            row.id = idx + 1
            return row
        })

        setTotalCutList(cutList)
    }

    useEffect(() => {
        let searchCriteria = "NOT_REAL_SEARCH_CRITERIA"
        if (params.order) {
            searchCriteria = params.order
        }
        const q = query(
            collection(db, "files"),
            where("docIdParent", "==", searchCriteria)
        )
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let tempRows = []
            querySnapshot.forEach((doc) => {
                const created = doc.data().created
                const fileName = doc.data().fileName
                const nameEditable = doc.data().nameEditable
                const uploadUid = doc.data().uid
                const docId = doc.id
                tempRows.push({
                    created,
                    fileName,
                    nameEditable,
                    uploadUid,
                    docId
                })
            });
            setFiles(tempRows)
        })
        return () => unsubscribe()
    }, [params.order])

    // This function subscribes to the doc's data only for lumberOrder changes.
    useEffect(() => {
        if (!params.order) {
            return;
        }
        const unsubscribe = onSnapshot(doc(db, "work_orders", params.order), (doc) => {
            setLumberOrder(doc.data().lumberOrder)
        });
        return () => unsubscribe()
    }, [params.order])

    useEffect(() => {
            const getReferredDoc = async () => {
                const docRef = doc(db, "work_orders", params.order)
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const fullDueDate = docSnap.data().dueDate.toDate()
                    const year = fullDueDate.getUTCFullYear()
                    const month = fullDueDate.getUTCMonth() + 1
                    const day = fullDueDate.getUTCDate()
                    const monthPadded = zeroPad(month, 2)
                    const dayPadded = zeroPad(day, 2)

                    setAddress(docSnap.data().address)
                    setClientName(docSnap.data().clientName)
                    setDueDate(`${year}-${monthPadded}-${dayPadded}`)
                    setIsFlush(docSnap.data().isFlush || false)
                    setInProduction(docSnap.data().inProduction)
                    setModelName(docSnap.data().modelName)
                    setTableData(docSnap.data().orderItems)
                    setStairsBuilt(docSnap.data().stairsBuilt)
                    setStairsShipped(docSnap.data().stairsShipped)
                    setWorkOrder(docSnap.data().workOrder)
                    setDisableSubmit(false)
                    setLumberOrder(docSnap.data().lumberOrder)
                    processCuts(docSnap.data().orderItems)
                } else {
                    // doc.data() will be undefined in this case
                    console.log("No such document!");
                }
            }

            if (params?.order) {
                getReferredDoc()
            } else {
                setAddress("")
                setClientName("")
                setDueDate("")
                setInProduction(false)
                setIsFlush(false)
                setModelName("")
                setStairsBuilt(false)
                setStairsShipped(false)
                setTableData([])
                setTotalCutList([])
                setWorkOrder("")
                setLumberOrder(null)
            }
        }, [params.order, stairType]
    )

    const handleDelete = useCallback(
        (id) => {
            console.log('h1', id)
            fileIdToDelete.current = id;
            open();
        },
        [open]
    );

    const handleDeleteCancel = useCallback(() => {
        fileIdToDelete.current = undefined;
        close();
    }, [close]);

    const handleDeleteConfirm = useCallback(async () => {

        const isDeleted = await deleteFile({
            fileId: fileIdToDelete.current,
        });
        console.log(isDeleted)
        if (isDeleted) {
            enqueueSnackbar("Successfully Deleted", {variant: "success"});
            // loadFiles()
        } else {
            enqueueSnackbar("Error while deleting", {variant: "error"});
        }
        fileIdToDelete.current = undefined;
        close();
    }, [close, enqueueSnackbar, deleteFile]);


    useEffect(() => {
        if (selectedFile) {
            console.log(selectedFile.type)
            if (selectedFile.type === "image/png" || selectedFile.type === "image/jpg" || selectedFile.type === "image/jpeg" || selectedFile.type === "application/pdf") {
                const storage = getStorage();
                const fileType = selectedFile.type.replace("image/", "").replace("application/", "")
                const customId = v4()
                const uploadRef = storageRef(storage, `${customId}.${fileType}`);
                const metadata = {
                    contentType: selectedFile.type,
                    customMetadata: {
                        uid,
                        docIdParent: params.order,
                        nameEditable: selectedFile.name,
                    }
                };
                let imageHeight, imageWidth
                if (selectedFile.type === "image/png" || selectedFile.type === "image/jpg" || selectedFile.type === "image/jpeg") {
                    let img = new Image()
                    img.src = window.URL.createObjectURL(selectedFile)
                    img.onload = () => {
                        imageHeight = img.naturalHeight
                        imageWidth = img.naturalWidth
                    }
                }
                const uploadTask = uploadBytesResumable(uploadRef, selectedFile, metadata)
                uploadTask.on('state_changed',
                    (snapshot) => {
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadPercentage(progress)
                        // console.log('Upload is ' + progress + '% done');
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                                break;
                            case 'running':
                                console.log('Upload is running');
                                break;
                            default:
                                console.log('Upload is ' + progress + '% done');
                        }
                    },
                    (error) => {
                        // Handle unsuccessful uploads
                        enqueueSnackbar('Error while uploading. Please refresh the page and try again.', {variant: "error"})
                        setUploadPercentage(0)
                        console.log(error)
                    },
                    () => {
                        // Handle successful uploads on complete
                        addDoc(collection(db, "files"), {
                            fileName: `${customId}.${fileType}`,
                            uid,
                            docIdParent: params.order,
                            nameEditable: selectedFile.name,
                            created: Timestamp.fromMillis(Date.parse(uploadTask.snapshot.metadata.timeCreated)),
                            updated: Timestamp.fromMillis(Date.parse(uploadTask.snapshot.metadata.updated)),
                            height: imageHeight ? imageHeight : 0,
                            width: imageWidth ? imageWidth : 0
                        }).then((docRef) => {
                            console.log("Document written with ID: ", docRef.id);
                            enqueueSnackbar('Upload Successful.', {variant: "success"})
                            // loadFiles()
                            setUploadPercentage(0)
                            setSelectedFile(null)
                        })
                    }
                );
            } else {
                enqueueSnackbar('Upload failed. File type not accepted', {variant: "error"})
                setUploadPercentage(0)
                setSelectedFile(null)
            }
        }
    }, [selectedFile, enqueueSnackbar,  params.order, uid])

    function clearFormFields() {
        setClientName("")
        setWorkOrder("")
        setAddress("")
        setModelName("")
        setDueDate("")
        setStairsBuilt(false)
        setInProduction(false)
        setStairsShipped(false)
    }

    async function addInteriorExtra1x4x14() {
        let strapping1x4x14 = false
        const allIds = apiRef.current.getAllRowIds();

        let allRows = allIds.map((id) => {
            let row = apiRef.current.getRow(id);
            for (let i = 0; i < row.cutList.length; i++) {
                if (row.cutList[i].material === "1x4") {
                    strapping1x4x14 = true
                }
            }
            return apiRef.current.getRow(id);
        });

        let firstRow = true

        if (!strapping1x4x14) {
            allRows = allIds.map((id) => {
                let row = apiRef.current.getRow(id);
                if (firstRow) {
                    row.cutList.push({
                        material: "1x4",
                        description: "Strapping",
                        length: 168,
                        count: 2,
                        displayOnCutList: false,
                    })
                    firstRow = false
                }
                apiRef.current.updateRows([{...row, isNew: false}]);
                return apiRef.current.getRow(id);
            });
        }

        await setTableData(allRows);
    }


    const createWorkOrderFirebase = async () => {
        if (stairType === "interior") {
            await addInteriorExtra1x4x14()
        }
        if (verifyCollectionObjects()) {
            const newDocRef = await addDoc(workOrdersCollectionRef, {
                address,
                clientName,
                created: serverTimestamp(),
                createEstimateEmail: false,
                createLumberOrder: true,
                dueDate: Timestamp.fromDate(new Date(dueDate)),
                inProduction,
                isFlush,
                modelName,
                orderItems: tableData,
                stairsBuilt,
                stairsShipped,
                stairType,
                uid,
                updated: serverTimestamp(),
                workOrder,
            })

            enqueueSnackbar('Successfully uploaded', {variant: "success"})
            logEvent(analytics, `Created Order: ${stairType}`);
            setTableData([])
            setTotalCutList([])
            setDisableSubmit(true)
            clearFormFields()
            setClone(false)
            navigate(`/${stairType}/${newDocRef.id}`)
        }
    };

    const cloneWorkOrder = () => {
        setClone(true)
        setClientName("")
        setWorkOrder("")
        setAddress("")
        setDueDate("")
        setFiles([])
        setStairsBuilt(false)
        setInProduction(false)
        setStairsShipped(false)

        const allIDs = apiRef.current.getAllRowIds()
        for (const ID of allIDs) {
            const row = apiRef.current.getRow(ID);
            const calculatedRow = handleCalculations(row, stairType, isFlush)
            apiRef.current.updateRows([{...calculatedRow, isNew: false}]);
        }
        const allRows = allIDs.map((id) => {
            return apiRef.current.getRow(id);
        });
        setTableData(allRows);
        processCuts(allRows)

        logEvent(analytics, `Clicked: Clone ${stairType}`);
    }

    const updateWorkOrderFirebase = async () => {
        if (stairType === "interior") {
            await addInteriorExtra1x4x14()
        }
        if (verifyCollectionObjects()) {
            const docRefSet = doc(db, "work_orders", params.order)
            await updateDoc(docRefSet, {
                address: address,
                clientName,
                dueDate: Timestamp.fromDate(new Date(dueDate)),
                isFlush,
                modelName,
                orderItems: tableData,
                updated: serverTimestamp(),
                workOrder,
                stairsShipped,
                stairsBuilt,
                inProduction,
            })
            enqueueSnackbar('Successfully updated', {variant: "success"})
            setTableData([])
            setTotalCutList([])
            setDisableSubmit(true)
            clearFormFields()
            navigate(`/${stairType}/`)
        }
    }

    const deleteWorkOrderFirebase = async () => {
        await deleteDoc(doc(db, "work_orders", params.order));
        enqueueSnackbar('Work order deleted.', {variant: "success"})
        navigate("/list")
    }

    const verifyCollectionObjects = () => {

        const schemaCheckResults = tableData.map((row) => {

            if (stairType === "veranda") {
                return verandaStairSchema.validate(row)

            } else if (stairType === "deck") {

                if (row.itemType === "Stairs") {
                    return deckStairSchema.validate(row)
                }
                return deckLandingSchema.validate(row)

            } else if (stairType === "garage") {

                if (row.itemType === "Stairs") {
                    return garageStairSchema.validate(row)
                }
                return garageLandingSchema.validate(row)

            } else if (stairType === "interior") {

                if (row.itemType === "Stairs") {
                    return interiorStairSchema.validate(row)
                }
                return interiorWinderSchema.validate(row)

            }
            return false
        })

        const rowsWithErrors = schemaCheckResults.filter(row => 'error' in row)
        const validForm = formCheck()
        if (rowsWithErrors.length) {
            enqueueSnackbar(rowsWithErrors[0].error.message, {variant: "error"})
            return false
        } else if (!validForm) {
            enqueueSnackbar('Please fill in Client Name, Work Order, Address, and Due Date.', {variant: "error"})
            return false
        }
        return true
    }

    const handleDeleteClick = (id) => (event) => {
        event.stopPropagation();
        apiRef.current.updateRows([{id, _action: 'delete'}]);
        const allIdsNew = apiRef.current.getAllRowIds();
        const allRows = allIdsNew.map((id) => {
            return apiRef.current.getRow(id);
        });
        setTableData(allRows);
        processCuts(allRows)
        if (!allRows.length) {
            setDisableSubmit(true)
        }
    };

    function formCheck() {
        return clientName.length && workOrder.length && address.length && dueDate.length
    }

    function EditToolbar() {
        const handleClick = () => {
            const allIds = apiRef.current.getAllRowIds();
            const newId = Math.max(0, ...allIds) + 1
            let run = 10
            if (stairType === "garage" || stairType === "interior") run = 9.875
            const defaultInitialData = {
                id: newId,
                nosing: "Yes",
                itemType: "Stairs",
                notes: "",
                numberOfStringersManual: null,
                run: run,
                stringerLengthManual: null,
            }
            if (stairType === "interior") {
                defaultInitialData.profileLeft = "No"
                defaultInitialData.profileRight = "No"
                defaultInitialData.profileLeftManual = null
                defaultInitialData.profileRightManual = null
            }
            apiRef.current.setRows([
                ...tableData,
                defaultInitialData
            ])
            const allIdsNew = apiRef.current.getAllRowIds();
            const allRows = allIdsNew.map((id) => {
                return apiRef.current.getRow(id);
            });
            setTableData(allRows);
            setDisableSubmit(false)
            logEvent(analytics, 'Item added to order');
        };
        return (
            <GridToolbarContainer>
                <Box
                    sx={{
                        display: "none",
                        displayPrint: "block",
                        textAlign: "center",
                        width: "100%",
                        pt: 2,
                        pb: 1,
                    }}
                >
                    {clientName} || {workOrder} || {address} || {dueDate} || {modelName}
                </Box>
                <Box sx={{displayPrint: "none"}}>
                    <Button color="primary" startIcon={<AddIcon/>} onClick={handleClick}>
                        Add Item
                    </Button>
                    <GridToolbarExport/>
                </Box>
            </GridToolbarContainer>
        );
    }

    const handleFlushUpdate = (isFlushBypassState) => {
        setIsFlushProcessing(true)
        try {
            const allIDs = apiRef.current.getAllRowIds()
            for (const ID of allIDs) {
                const row = apiRef.current.getRow(ID);
                const calculatedRow = handleCalculations(row, stairType, isFlushBypassState)
                apiRef.current.updateRows([{...calculatedRow, isNew: false}]);
            }
            const allRows = allIDs.map((id) => {
                return apiRef.current.getRow(id);
            });
            setTableData(allRows);
            processCuts(allRows)
            setIsFlushProcessing(false)
        } catch (e) {
            enqueueSnackbar("Error while applying flush change.", {variant: "error"})
            setIsFlushProcessing(false)
        }
    }

    const handleRowEditStop = async (params) => {
        const row = apiRef.current.getRow(params.id);
        const calculatedRow = handleCalculations(row, stairType, isFlush)
        apiRef.current.updateRows([{...calculatedRow, isNew: false}]);

        const allIDs = apiRef.current.getAllRowIds();
        const allRows = allIDs.map((id) => {
            return apiRef.current.getRow(id);
        });
        setTableData(allRows);
        processCuts(allRows)
    };

    function valueFormatterFractions(params) {
        return setFractions(params.value)
    }


    const actionsColumn = {
        field: "actions", type: "actions", headerName: "Actions", width: 100, getActions: ({id}) => {
            return [
                <GridActionsCellItem
                    icon={<DeleteIcon/>}
                    label="Delete"
                    onClick={handleDeleteClick(id)}
                    color="inherit"
                />,
            ]
        }
    }
    const stairSetColumn = {field: 'stairSet', headerName: 'Stair Set', width: 150, editable: true}
    const widthColumn = {
        field: 'width',
        headerName: 'Width',
        width: 75,
        editable: true,
        type: "number",
        valueFormatter: valueFormatterFractions
    }
    const riseColumn = {
        field: 'rise',
        headerName: 'Rise',
        width: 100,
        editable: true,
        type: "number",
        valueFormatter: valueFormatterFractions
    }
    const runColumn = {
        field: 'run',
        headerName: 'Run',
        width: 100,
        editable: true,
        type: "number",
        valueFormatter: valueFormatterFractions
    }
    const risesColumn = {field: 'rises', headerName: 'Rises', width: 100, editable: true, type: "number"}
    const depthLandingColumn = {
        field: 'depthLanding',
        headerName: 'Depth (Landing)',
        width: 150,
        editable: true,
        type: "number",
        valueFormatter: valueFormatterFractions
    }
    const nosingColumnSpecific = {
        field: 'nosing', headerName: 'Nosing', width: 160, editable: true, type: "singleSelect",
        valueOptions: ["Yes", "No - u/s of door", 'No - u/s 1.25" decking', 'No - u/s 1.5" decking', 'No - goes to top of decking']
    }
    const nosingColumnGeneral = {
        field: 'nosing', headerName: 'Nosing', width: 160, editable: true, type: "singleSelect",
        valueOptions: ["Yes", "No"]
    }
    const notesColumn = {field: 'notes', headerName: 'Notes', width: 400, editable: true,}
    const numberOfStringersManualColumn = {
        field: 'numberOfStringersManual',
        headerName: '# of Stringers Manual',
        width: 175,
        editable: true,
        type: "number"
    }
    const stringerLengthManualColumn = {
        field: 'stringerLengthManual',
        headerName: 'Stringer Length Ft Manual',
        width: 200,
        editable: true,
        type: "number"
    }
    const totalHeightColumn = {
        field: 'totalHeight',
        headerName: 'Total Height',
        width: 125,
        editable: false,
        type: "number",
        valueFormatter: valueFormatterFractions
    }
    const numberOfStringersColumn = {
        field: 'numberOfStringers',
        headerName: '# of Stringers',
        width: 120,
        editable: false,
        type: "number"
    }
    const stringerLengthColumn = {
        field: 'stringerLength',
        headerName: 'Stringer Length Ft',
        width: 150,
        editable: false,
        type: "number"
    }
    const bottomRiserHeightColumn = {
        field: 'bottomRiserHeight',
        headerName: 'Bottom Riser Height',
        width: 160,
        editable: false,
        type: "number",
        valueFormatter: valueFormatterFractions
    }
    const middleRiserHeightColumn = {
        field: 'middleRiserHeight',
        headerName: 'Middle Riser Height',
        width: 160,
        editable: false,
        type: "number",
        valueFormatter: valueFormatterFractions
    }
    const topRiserHeightColumn = {
        field: 'topRiserHeight',
        headerName: 'Top Riser Height',
        width: 150,
        editable: false,
        type: "number",
        valueFormatter: valueFormatterFractions
    }
    const blockingCount2x4x8Column = {
        field: 'blockingCount2x4x8',
        headerName: '# of Blocking 2x4x8',
        width: 150,
        editable: false,
        type: "number"
    }
    const treadsCount = {
        field: 'treadsCount',
        headerName: '# of Treads',
        width: 150,
        editable: false,
        type: "number"
    }
    const treadsCount2x6Column = {
        field: 'treadsCount2x6',
        headerName: '# of Treads 2x6',
        width: 150,
        editable: false,
        type: "number"
    }
    const itemTypeColumnDeckGarage = {
        field: 'itemType', headerName: 'Item Type', width: 160, editable: true, type: "singleSelect",
        valueOptions: ["Stairs", "Landing"]
    }
    const itemTypeColumnInterior = {
        field: 'itemType', headerName: 'Item Type', width: 160, editable: true, type: "singleSelect",
        valueOptions: ["Stairs", "Winder - 2 Step", "Winder - 3 Step"]
    }
    const profileLeftColumn = {
        field: 'profileLeft', headerName: 'Profile Left', width: 100, editable: true, type: "singleSelect",
        valueOptions: ["No", "1a", "1b", "2", "3", "4", "5"]
    }
    const profileRightColumn = {
        field: 'profileRight', headerName: 'Profile Right', width: 120, editable: true, type: "singleSelect",
        valueOptions: ["No", "1a", "1b", "2", "3", "4", "5"]
    }
    const profileLeftManualColumn = {
        field: 'profileLeftManual',
        headerName: 'Profile Left Manual',
        width: 150,
        editable: true,
        type: "number",
        valueFormatter: valueFormatterFractions
    }
    const profileRightManualColumn = {
        field: 'profileRightManual',
        headerName: 'Profile Right Manual',
        width: 150,
        editable: true,
        type: "number",
        valueFormatter: valueFormatterFractions
    }


    const verandaColumns = [
        actionsColumn,
        stairSetColumn,
        widthColumn,
        riseColumn,
        runColumn,
        risesColumn,
        nosingColumnSpecific,
        notesColumn,
        numberOfStringersManualColumn,
        stringerLengthManualColumn,
        totalHeightColumn,
        numberOfStringersColumn,
        stringerLengthColumn,
        bottomRiserHeightColumn,
        middleRiserHeightColumn,
        topRiserHeightColumn,
        treadsCount2x6Column
    ]

    const deckColumns = [
        actionsColumn,
        stairSetColumn,
        itemTypeColumnDeckGarage,
        widthColumn,
        riseColumn,
        runColumn,
        risesColumn,
        depthLandingColumn,
        nosingColumnSpecific,
        notesColumn,
        numberOfStringersManualColumn,
        stringerLengthManualColumn,
        totalHeightColumn,
        numberOfStringersColumn,
        stringerLengthColumn,
        topRiserHeightColumn,
        blockingCount2x4x8Column,
        treadsCount2x6Column
    ]

    const garageColumns = [
        actionsColumn,
        stairSetColumn,
        itemTypeColumnDeckGarage,
        widthColumn,
        riseColumn,
        runColumn,
        risesColumn,
        depthLandingColumn,
        nosingColumnGeneral,
        notesColumn,
        numberOfStringersManualColumn,
        stringerLengthManualColumn,
        totalHeightColumn,
        numberOfStringersColumn,
        stringerLengthColumn,
        bottomRiserHeightColumn,
        middleRiserHeightColumn,
        topRiserHeightColumn,
        treadsCount,
    ]

    const interiorColumns = [
        actionsColumn,
        stairSetColumn,
        itemTypeColumnInterior,
        widthColumn,
        riseColumn,
        runColumn,
        risesColumn,
        profileLeftColumn,
        profileRightColumn,
        nosingColumnGeneral,
        notesColumn,
        numberOfStringersManualColumn,
        stringerLengthManualColumn,
        totalHeightColumn,
        numberOfStringersColumn,
        stringerLengthColumn,
        bottomRiserHeightColumn,
        middleRiserHeightColumn,
        topRiserHeightColumn,
        treadsCount,
        profileLeftManualColumn,
        profileRightManualColumn
    ]


    let columns

    if (stairType + "Columns" === Object.keys({verandaColumns})[0]) {
        columns = verandaColumns
    } else if (stairType + "Columns" === Object.keys({deckColumns})[0]) {
        columns = deckColumns
    } else if (stairType + "Columns" === Object.keys({garageColumns})[0]) {
        columns = garageColumns
    } else if (stairType + "Columns" === Object.keys({interiorColumns})[0]) {
        columns = interiorColumns
    }

    return (
        <div>
            <CommonForm clientName={clientName} onChangeClientName={(e) => setClientName(e.target.value)}
                        workOrder={workOrder} onChangeWorkOrder={(e) => setWorkOrder(e.target.value)}
                        address={address} onChangeAddress={(e) => setAddress(e.target.value)}
                        dueDate={dueDate} onChangeDueDate={(e) => setDueDate(e.target.value)}
                        modelName={modelName} onChangeModelName={(e) => setModelName(e.target.value)}
                        stairsBuilt={stairsBuilt} onChangeStairsBuilt={(e) => setStairsBuilt(e.target.checked)}
                        inProduction={inProduction} onChangeInProduction={(e) => setInProduction(e.target.checked)}
                        stairsShipped={stairsShipped}
                        onChangeStairsShipped={(e) => setStairsShipped(e.target.checked)}
                        isFlush={isFlush} onChangeIsFlush={(e) => {
                setIsFlush(e.target.checked)
                handleFlushUpdate(e.target.checked)
            }}
                        stairType={stairType}
                        isFlushProcessing={isFlushProcessing}
            />


            <Box sx={{width: "100%", my: "15px"}}>
                <DataGridPremium
                    autoHeight
                    apiRef={apiRef}
                    editMode="row"
                    rows={tableData}
                    columns={columns}
                    onRowEditStop={handleRowEditStop}
                    components={{
                        Toolbar: EditToolbar,
                    }}
                />
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-evenly'
            }}>
                {!params.order || clone
                    ? (
                        <Button
                            variant="contained"
                            onClick={createWorkOrderFirebase}
                            disabled={disableSubmit}
                            color="primary"
                        >
                            Submit Work Order
                        </Button>
                    )
                    : (
                        <>
                            <Button
                                variant="contained"
                                onClick={updateWorkOrderFirebase}
                                color="primary"
                            >
                                Update Work Order
                            </Button>
                            <Button
                                variant="contained"
                                onClick={cloneWorkOrder}
                                color="primary"
                            >
                                Clone Work Order
                            </Button>
                            <Button
                                variant="contained"
                                onClick={deleteWorkOrderFirebase}
                                color="primary"
                            >
                                Delete Work Order
                            </Button>
                        </>
                    )}
            </Box>
            <Paper elevation={15} sx={{width: "850px", mx: "auto", py: "10px", mb: "25px", mt: "60px"}}>

                <CutListDisplay
                    totalCutList={totalCutList}
                    workOrder={workOrder}
                    address={address}
                    clientName={clientName}
                    dueDate={dueDate}
                    modelName={modelName}
                />
                <LumberOrderDisplay
                    lumberOrder={lumberOrder}
                    workOrder={workOrder}
                    address={address}
                    clientName={clientName}
                    dueDate={dueDate}
                    modelName={modelName}
                />
            </Paper>
            {params.order && !clone &&
                <Paper elevation={15} sx={{width: "850px", mx: "auto", py: "10px", mb: "25px", mt: "60px"}}>

                    {params.order &&
                        <Box sx={{height: 80, width: "800px", mx: "auto", my: "25px"}}>
                            <Typography variant="h4" gutterBottom component="div" mt={2} textAlign="center">
                                Files
                            </Typography>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <Button variant="contained" component="label">
                                    Upload File
                                    <input hidden type="file" onChange={(e) => setSelectedFile(e.target.files[0])}
                                    />
                                </Button>
                                {uploadPercentage !== 0 && <CircularProgressWithLabel value={uploadPercentage}/>}
                            </Stack>
                        </Box>
                    }
                    {params.order &&
                        <Box sx={{height: 450, width: "800px", mx: "auto", my: "25px"}}>
                            <FilesGrid files={files} onDelete={handleDelete} workOrder={workOrder}/>
                            <Dialog open={isOpen} onClose={close} aria-labelledby="delete-dialog-title">
                                <DialogTitle style={{cursor: "move"}} id="delete-dialog-title">
                                    Confirmation
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText>Are you sure you want to delete?</DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus disabled={deleting} onClick={handleDeleteCancel}>
                                        Cancel
                                    </Button>
                                    <Button disabled={deleting} onClick={handleDeleteConfirm}>
                                        Delete
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    }
                </Paper>
            }
        </div>
    );
}

