import { useCallback, useMemo, useState } from "react";

export function useDialog(initialState = false) {
    const [isOpen, setOpen] = useState(initialState);

    const close = useCallback(() => {
        setOpen(false);
    }, []);

    const open = useCallback(() => {
        setOpen(true);
    }, []);

    return useMemo(
        () => ({
            isOpen,
            open,
            close,
        }),
        [isOpen, close, open]
    );
}
