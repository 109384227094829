import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {signInWithEmailAndPassword} from "firebase/auth";
import {auth} from "../firebase-config";
import {Box, Button, Container, TextField} from "@mui/material";


export function LoginPage(){
  const [loginEmail, setLoginEmail] = useState("")
  const [loginPassword, setLoginPassword] = useState("")

  let navigate = useNavigate();
  let location = useLocation();

  let from = location.state?.from?.pathname || "/";

  const login = async () => {
    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword)
      navigate(from, { replace: true })
    } catch (error) {
      console.log(error.message)
    }
  }
  return (
    <div>
      <Container maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box textAlign="center">
            <h3>Stair Builder App Login</h3>
          </Box>
          <Box sx={{ mt: 1 }}>
            <TextField
              label="Email"
              fullWidth
              onChange={(e)=>setLoginEmail(e.target.value)}
            />
            <TextField
              label="Password"
              fullWidth
              type="password"
              onChange={(e)=>setLoginPassword(e.target.value)}
              sx={{
                marginTop: 3,
              }}
            />
            <Button
              variant="contained"
              fullWidth
              onClick={login}
              sx={{
                marginTop: 3,
              }}
            >
              Login
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  )
}

