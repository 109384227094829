// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics";
const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check");


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCu-m4-sMEml30QirIumjm-QHUpvJ6K4uM",
  authDomain: "stair-builder-program.firebaseapp.com",
  projectId: "stair-builder-program",
  storageBucket: "stair-builder-program.appspot.com",
  messagingSenderId: "1055372696739",
  appId: "1:1055372696739:web:a44f16e6cd2a7900be7316"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;  // Use this when you need to get a local debug token to add to app check
initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6Lcw6r4dAAAAALpM12drguKGoPtTDBWU_UrQzLga'),
  isTokenAutoRefreshEnabled: true
});


export const auth = getAuth(app)
export const db = getFirestore(app)
export const analytics = getAnalytics(app);
