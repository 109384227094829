import {useMemo} from "react";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from '@mui/icons-material/Print';
import {
    DataGridPremium,
    GridActionsCellItem,
} from "@mui/x-data-grid-premium";
import LaunchIcon from '@mui/icons-material/Launch';
import {getDownloadURL, getStorage, ref as storageRef, getBlob} from "firebase/storage";
import printJS from "print-js";


export function FilesGrid({files, onDelete, workOrder, ...rest}) {
    const filesWithID = useMemo(() => {
        return files.map((file) => {
            console.log(file.docId)
            return {...file, id: file.docId}
        })
    }, [files])
    // const loading = false


    const COLUMNS = useMemo(
        () => [
            // { field: "fileName", headerName: "fileName", width: 290, editable: false },
            {field: "nameEditable", headerName: "Name", width: 380, editable: false},
            {
                field: "created", headerName: "Uploaded", width: 180, editable: false,
                valueGetter: ({value}) => {
                    if (value) {
                        const date_obj = new Date(value.seconds * 1000)
                        const options = {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            minute: "numeric",
                            hour: "numeric"
                        };
                        return date_obj.toLocaleDateString('en-US', options)
                    }
                }
            },
            {
                field: "actions",
                type: "actions",
                headerName: "Actions",
                width: 230,
                getActions: ({id, row}) => {
                    return [

                        <GridActionsCellItem
                            key="action_delete"
                            icon={<DeleteOutlinedIcon/>}
                            label="Delete"
                            disabled={row.nameEditable === "complete_sb.pdf"}
                            onClick={() => onDelete(id)}
                            color="inherit"
                            sx={{mx: 1}}
                        />,
                        <GridActionsCellItem
                            key="action_preview"
                            onClick={() => {
                                if (row.fileName) {
                                    const storage = getStorage();
                                    const pathReference = storageRef(storage, row.fileName);
                                    getDownloadURL(pathReference).then((res) => {
                                        window.open(res, "_blank");
                                    })
                                }
                            }}
                            icon={<LaunchIcon/>}
                            label="Launch"
                            color="inherit"
                            sx={{mx: 1}}

                        />,
                        <GridActionsCellItem
                            key="action_download"
                            onClick={async () => {
                                if (row.fileName) {
                                    const storage = getStorage()
                                    const fileRef = storageRef(storage, row.fileName)
                                    const blob = await getBlob(fileRef)
                                    const blobUrl = URL.createObjectURL(blob)
                                    const link = document.createElement('a')
                                    link.href = blobUrl
                                    let downloadName = row.nameEditable
                                    if (downloadName === "complete_sb.pdf") {
                                        const pdfTitle = workOrder.replace(/[^a-zA-Z0-9 ]/g, '')
                                        downloadName = `WorkOrder-${pdfTitle}.pdf`
                                    }
                                    link.download = `${downloadName}`
                                    link.click()
                                }
                            }}
                            icon={<FileDownloadOutlinedIcon/>}
                            label="Download"
                            color="inherit"
                            sx={{mx: 1}}
                        />,
                        <GridActionsCellItem
                            key="action_print"
                            onClick={async () => {
                                if (row.fileName) {
                                    const storage = getStorage()
                                    const fileRef = storageRef(storage, row.fileName)
                                    const blob = await getBlob(fileRef)
                                    const blobUrl = URL.createObjectURL(blob)
                                    if (row.fileName.includes(".pdf")) {
                                        printJS(blobUrl)
                                    }
                                    if (row.fileName.includes(".png") ||
                                        row.fileName.includes(".jpg") ||
                                        row.fileName.includes(".jpeg")
                                    ) {
                                        printJS(blobUrl, 'image')
                                    }
                                }
                            }}
                            icon={<PrintIcon/>}
                            disabled={
                                !row.fileName.includes(".pdf") &&
                                !row.fileName.includes(".png") &&
                                !row.fileName.includes(".jpg") &&
                                !row.fileName.includes(".jpeg")
                            }
                            label="Print"
                            color="inherit"
                            sx={{mx: 1}}
                        />

                    ];
                },
            },
        ],
        [onDelete, workOrder]
    );

    return (
        <DataGridPremium
            editMode="row"
            rows={filesWithID}
            columns={COLUMNS}
            density={"compact"}
            {...rest}
        />
    );
}
