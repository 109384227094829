import Joi from "joi";

let baseStairSchema = {
  id: Joi.any(),
  isNew: Joi.any(),
  stairSet: Joi.string().required(),
  itemType: Joi.string().required(),
  width: Joi.number().positive().required(),
  nosing: Joi.string().required(),
  notes: Joi.string().allow(""),
  run: Joi.number().required(),
  rise: Joi.number().positive().required(),
  rises: Joi.number().positive().required(),
  totalHeight: Joi.number().positive().required(),
  numberOfStringers: Joi.number().positive().required(),
  numberOfStringersManual: Joi.number().positive().allow(null),
  stringerLength: Joi.number().positive().required(),
  stringerLengthManual: Joi.number().positive().allow(null),
  topRiserHeight: Joi.number().positive().required(),
  blockingCount2x4x8: Joi.number().min(0).required(),
  price: Joi.number().positive().required(),
  cutList: Joi.array(),
}

export const verandaStairSchema = Joi.object({
  ...baseStairSchema,
  bottomRiserHeight: Joi.number().min(0).required(),
  middleRiserHeight: Joi.number().min(0).required(),
  treadsCount2x6: Joi.number().positive().required(),
})

export const deckStairSchema =  Joi.object({
  ...baseStairSchema,
  treadsCount2x6: Joi.number().positive().required(),
  depthLanding: Joi.number().allow(null),
})

export const deckLandingSchema = Joi.object({
  id: Joi.any(),
  isNew: Joi.any(),
  stairSet: Joi.string().required(),
  itemType: Joi.string().required(),
  totalHeight: Joi.number().positive().allow(null),
  width: Joi.number().positive().required(),
  depthLanding: Joi.number().positive().required(),
  nosing: Joi.string().allow(""),
  notes: Joi.string().allow(""),
  run: Joi.number().allow(null),
  rise: Joi.number().positive().allow(null),
  rises: Joi.number().positive().allow(null),
  numberOfStringers: Joi.number().positive().allow(null),
  numberOfStringersManual: Joi.number().positive().allow(null),
  stringerLength: Joi.number().positive().allow(null),
  stringerLengthManual: Joi.number().positive().allow(null),
  topRiserHeight: Joi.number().positive().allow(null),
  blockingCount2x4x8: Joi.number().positive().allow(null),
  treadsCount2x6: Joi.number().positive().allow(null),
  price: Joi.number().positive().required(),
  cutList: Joi.array(),
})

export const garageStairSchema = Joi.object({
  ...baseStairSchema,
  bottomRiserHeight: Joi.number().min(0).required(),
  middleRiserHeight: Joi.number().min(0).required(),
  treadsCount: Joi.number().positive().required(),
  depthLanding: Joi.number().allow(null),
})

export const garageLandingSchema = Joi.object({
  id: Joi.any(),
  isNew: Joi.any(),
  stairSet: Joi.string().required(),
  itemType: Joi.string().required(),
  totalHeight: Joi.number().positive().allow(null),
  width: Joi.number().positive().required(),
  depthLanding: Joi.number().positive().required(),
  nosing: Joi.string().allow(""),
  notes: Joi.string().allow(""),
  run: Joi.number().allow(null),
  rise: Joi.number().positive().allow(null),
  rises: Joi.number().positive().allow(null),
  numberOfStringers: Joi.number().positive().allow(null),
  numberOfStringersManual: Joi.number().positive().allow(null),
  stringerLength: Joi.number().positive().allow(null),
  stringerLengthManual: Joi.number().positive().allow(null),
  bottomRiserHeight: Joi.number().min(0).allow(null),
  middleRiserHeight: Joi.number().min(0).allow(null),
  topRiserHeight: Joi.number().positive().allow(null),
  treadsCount: Joi.number().positive().allow(null),
  price: Joi.number().positive().required(),
  cutList: Joi.array(),
})

export const interiorStairSchema = Joi.object({
  ...baseStairSchema,
  profileLeft: Joi.string().required(),
  profileRight: Joi.string().required(),
  profileLeftManual: Joi.number().positive().allow(null),
  profileRightManual: Joi.number().positive().allow(null),
  bottomRiserHeight: Joi.number().min(0).required(),
  middleRiserHeight: Joi.number().min(0).required(),
  treadsCount: Joi.number().positive().required(),
})

export const interiorWinderSchema = Joi.object({
  id: Joi.any(),
  isNew: Joi.any(),
  stairSet: Joi.string().required(),
  itemType: Joi.string().required(),
  totalHeight: Joi.number().positive().allow(null),
  width: Joi.number().positive().allow(null),
  profileLeft: Joi.string().allow(""),
  profileRight: Joi.string().allow(""),
  profileLeftManual: Joi.number().positive().allow(null),
  profileRightManual: Joi.number().positive().allow(null),
  nosing: Joi.string().required(),
  notes: Joi.string().allow(""),
  run: Joi.number().allow(null),
  rise: Joi.number().positive().allow(null),
  rises: Joi.number().positive().allow(null),
  numberOfStringers: Joi.number().positive().allow(null),
  numberOfStringersManual: Joi.number().positive().allow(null),
  stringerLength: Joi.number().positive().allow(null),
  stringerLengthManual: Joi.number().positive().allow(null),
  bottomRiserHeight: Joi.number().min(0).allow(null),
  middleRiserHeight: Joi.number().min(0).allow(null),
  topRiserHeight: Joi.number().positive().allow(null),
  treadsCount: Joi.number().positive().allow(null),
  price: Joi.number().positive().required(),
  cutList: Joi.array(),
})