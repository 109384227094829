import {Box, gridClasses, Typography} from "@mui/material";
import {DataGridPremium, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid-premium";
import {useMemo, useState} from "react";

export function LumberOrderDisplay(props) {

    const rows = useMemo(()=> {
        if (props.lumberOrder) {
            let result = [];
            let idCounter = 1;  // Initialize the counter
            for (let material in props.lumberOrder) {
                let materialObject = props.lumberOrder[material];

                // For each material, loop over its dimensions
                for (let dimension in materialObject) {
                    let count = materialObject[dimension];

                    // Push each material, dimension, and count as an object into the results array
                    result.push({
                        id: idCounter,  // Assign the current counter value as id
                        material: material,
                        dimension: dimension,
                        count: count
                    });

                    idCounter++;  // Increment the counter
                }
            }

            return result
        } else {
            return []
        }

    }, [props.lumberOrder])

    const [sortModel, setSortModel] = useState([
        {
            field: "material",
            sort: "asc",
        },
        {
            field: "dimension",
            sort: "asc",
        },
    ])


    const columns = [
        { field: 'material', headerName: 'Material', width: 125, editable: false, type: "string"},
        { field: 'dimension', headerName: 'Dimension', width: 125, editable: false, type: "string" },
        { field: 'count', headerName: 'Count', width: 100, editable: false, type: "number" },
    ];

    function EditToolbar() {
        return (
            <GridToolbarContainer className={gridClasses.toolbarContainer}>
                <Box
                    sx={{
                        display: "none",
                        displayPrint: "block",
                        textAlign: "center",
                        width: "100%",
                        pt: 2,
                        pb: 1,
                        pl: 1,
                    }}
                >
                    {props.clientName} || {props.workOrder} || {props.address} || {props.dueDate} || {props.modelName}
                </Box>
                <Box sx={{displayPrint: "none"}}>
                    <GridToolbarExport/>
                </Box>
            </GridToolbarContainer>
        );
    }


    return (
        <Box>
            <Typography variant="h4" gutterBottom component="div" mt={2} textAlign="center">
                Lumber Order
            </Typography>
            <Box sx={{ width: "800px", mx: "auto", my: "25px"}}>
                <DataGridPremium
                    autoHeight
                    editMode="row"
                    rows={rows}
                    columns={columns}
                    density={"compact"}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    components={{
                        Toolbar: EditToolbar,
                    }}
                />
            </Box>

        </Box>
    )
}

