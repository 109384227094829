import { useCallback, useMemo, useState } from "react";

import { doc, deleteDoc } from "firebase/firestore";
import {db} from "../firebase-config";


export function useFileDelete() {
    const [loading, setLoading] = useState(false);

    const deleteFn = useCallback(
        async ({ fileId }) => {
            if (fileId) {
                setLoading(true);
                const fileRef = doc(db, `files/${fileId}`);

                try {
                    await deleteDoc(fileRef);
                    setLoading(false);

                    return true;
                } catch (_) {
                    setLoading(false);
                }
            }

            return false;
        },
        []
    );

    return useMemo(() => [deleteFn, loading], [deleteFn, loading]);
}
