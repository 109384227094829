import {DataGridPremium, GridActionsCellItem, useGridApiRef} from "@mui/x-data-grid-premium";
import {collection, getDocs, query, where, orderBy, limit} from 'firebase/firestore'
import {analytics, db} from "../../firebase-config";
import {Box, Button, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {logEvent} from "firebase/analytics";
import {getBlob, getDownloadURL, getStorage, ref as storageRef} from "firebase/storage";
import LaunchIcon from "@mui/icons-material/Launch";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import printJS from "print-js";
import PrintIcon from "@mui/icons-material/Print";
import {useSnackbar} from "notistack";
import PDFMerger from "pdf-merger-js/browser";
import FetchCutHistory from "../../components/FetchCutHistory";

const rows = [];


export function ListPage(props) {
    const apiRef = useGridApiRef();
    let navigate = useNavigate()
    const user = props.user
    const {enqueueSnackbar} = useSnackbar()
    const [selectionModel, setSelectionModel] = useState([]);
    const [tableData, setTableData] = useState([...rows])

    const [sortModel, setSortModel] = useState([
        {
            field: "dueDate",
            sort: "asc",
        },
    ])


    const zeroPad = (num, places) => String(num).padStart(places, '0')


    useEffect(() => {
            const loadDataGrid = async () => {
                const q = query(collection(db, "work_orders"),
                    where("stairsShipped", "==", false),
                    where("uid", "==", user.uid),
                    orderBy("created", "desc"))
                const querySnapshot = await getDocs(q);
                let tempRows = []
                querySnapshot.forEach((doc) => {
                    const fullDueDate = doc.data().dueDate.toDate()
                    const year = fullDueDate.getUTCFullYear()
                    const month = fullDueDate.getUTCMonth() + 1
                    const day = fullDueDate.getUTCDate()
                    const monthPadded = zeroPad(month, 2)
                    const dayPadded = zeroPad(day, 2)
                    const data = {
                        id: doc.id,
                        clientName: doc.data().clientName,
                        address: doc.data().address,
                        workOrder: doc.data().workOrder,
                        stairType: doc.data().stairType,
                        modelName: doc.data().modelName,
                        dueDate: `${year}-${monthPadded}-${dayPadded}`,
                        orderItems: doc.data().orderItems.length,
                        inProduction: doc.data().inProduction,
                        stairsBuilt: doc.data().stairsBuilt,
                        stairsShipped: doc.data().stairsShipped,
                    }
                    tempRows.push(data)
                });

                setTableData([...tempRows])
            }
            loadDataGrid()
        }, [user.uid]
    )

    const handleRowClick = async (params) => {
        navigate(`/${params.row.stairType}/${params.id}`)
    }

    const handleViewAllOrders = async (limitResults) => {
        let q
        if (limitResults) {
            q = query(collection(db, "work_orders"),
                where("uid", "==", user.uid),
                orderBy("created", "desc"),
                limit(limitResults))
        } else {
            q = query(collection(db, "work_orders"),
                where("uid", "==", user.uid),
                orderBy("created", "desc"))
        }
        setSortModel([
            {
                field: "dueDate",
                sort: "desc",
            },
        ])
        const querySnapshot = await getDocs(q);
        let tempRows = []
        querySnapshot.forEach((doc) => {
            const fullDueDate = doc.data().dueDate.toDate()
            const year = fullDueDate.getUTCFullYear()
            const month = fullDueDate.getUTCMonth() + 1
            const day = fullDueDate.getUTCDate()
            const monthPadded = zeroPad(month, 2)
            const dayPadded = zeroPad(day, 2)
            const data = {
                id: doc.id,
                clientName: doc.data().clientName,
                address: doc.data().address,
                workOrder: doc.data().workOrder,
                stairType: doc.data().stairType,
                modelName: doc.data().modelName,
                dueDate: `${year}-${monthPadded}-${dayPadded}`,
                orderItems: doc.data().orderItems.length,
                inProduction: doc.data().inProduction,
                stairsBuilt: doc.data().stairsBuilt,
                stairsShipped: doc.data().stairsShipped,
            }
            tempRows.push(data)
        });

        setTableData([...tempRows])
        logEvent(analytics, 'Clicked: View All Orders');

    }

    const columns = [
        {
            field: "actions",
            type: "actions",
            headerName: "Files",
            width: 150,
            getActions: ({row}) => {
                return [
                    <GridActionsCellItem
                        key="action_preview"
                        onClick={async () => {
                            try {
                                const storage = getStorage();
                                const pathReference = storageRef(storage, `complete-${row.id}.pdf`);
                                const res = await getDownloadURL(pathReference)
                                window.open(res, "_blank");
                            } catch (e) {
                                console.log(e)
                                console.log(e.code)
                                if (e.code === "storage/object-not-found") {
                                    enqueueSnackbar("No PDF created for this item.", {variant: "error"})
                                } else {
                                    enqueueSnackbar("Unknown error.", {variant: "error"})
                                }
                            }

                        }}
                        icon={<LaunchIcon/>}
                        label="Launch"
                        color="inherit"
                        sx={{mx: .5}}
                    />,
                    <GridActionsCellItem
                        key="action_download"
                        onClick={async () => {
                            try {
                                const storage = getStorage()
                                const fileRef = storageRef(storage, `complete-${row.id}.pdf`)
                                const blob = await getBlob(fileRef)
                                const blobUrl = URL.createObjectURL(blob)
                                const link = document.createElement('a')
                                link.href = blobUrl
                                const pdfTitle = row.workOrder.replace(/[^a-zA-Z0-9 ]/g, '')
                                link.download = `WorkOrder-${pdfTitle}.pdf`
                                link.click()
                            } catch (e) {
                                console.log(e)
                                console.log(e.code)
                                if (e.code === "storage/object-not-found") {
                                    enqueueSnackbar("No PDF created for this item.", {variant: "error"})
                                } else {
                                    enqueueSnackbar("Unknown error.", {variant: "error"})
                                }
                            }

                        }}
                        icon={<FileDownloadOutlinedIcon/>}
                        label="Download"
                        color="inherit"
                        sx={{mx: .5}}
                    />,
                    <GridActionsCellItem
                        key="action_print"
                        onClick={async () => {
                            try {
                                const storage = getStorage()
                                const fileRef = storageRef(storage, `complete-${row.id}.pdf`)
                                const blob = await getBlob(fileRef)
                                const blobUrl = URL.createObjectURL(blob)
                                printJS(blobUrl)
                            } catch (e) {
                                console.log(e)
                                console.log(e.code)
                                if (e.code === "storage/object-not-found") {
                                    enqueueSnackbar("No PDF created for this item.", {variant: "error"})
                                } else {
                                    enqueueSnackbar("Unknown error.", {variant: "error"})

                                }
                            }
                        }}
                        icon={<PrintIcon/>}
                        label="Print"
                        color="inherit"
                        sx={{mx: .5}}
                    />

                ];
            },
        },
        {field: 'clientName', headerName: 'Client Name', width: 150, editable: false},
        {field: 'address', headerName: 'Address', width: 150, editable: false},
        {field: 'workOrder', headerName: 'Work Order', width: 150, editable: false},
        {field: 'stairType', headerName: 'Stair Type', width: 125, editable: false},
        {field: 'modelName', headerName: 'Model Name', width: 125, editable: false},
        // { field: 'created', headerName: 'Created', width: 150, editable: false },
        {field: 'dueDate', headerName: 'Due Date', width: 125, editable: false},
        {field: 'orderItems', headerName: 'Order Items', width: 125, editable: false, align: "center"},
        {
            field: 'inProduction',
            headerName: 'In Production',
            width: 115,
            editable: false,
            type: "boolean",
            align: "center"
        },
        {
            field: 'stairsBuilt',
            headerName: 'Stairs Built',
            width: 95,
            editable: false,
            type: "boolean",
            align: "center"
        },
        {
            field: 'stairsShipped',
            headerName: 'Stairs Shipped',
            width: 115,
            editable: false,
            type: "boolean",
            align: "center"
        },
    ];


    return (
        <Box>
            <Box
                sx={{
                    maxWidth: "1485px",
                    mx: "auto"
                }}

            >
                <Stack
                    direction={"row"}
                    spacing={4}
                    justifyContent={"left"}
                    sx={{mt: 2}}
                >
                    <Box>
                        <Button
                            onClick={() => handleViewAllOrders(250)}
                            variant={"contained"}
                        >
                            View Last 250 Orders
                        </Button>
                    </Box>

                    <Button onClick={async () => {
                        console.log(selectionModel)
                        if (selectionModel.length) {
                            try {
                                const toProcess = selectionModel
                                const blobsToMerge = []
                                const storage = getStorage()
                                for (const item of toProcess) {
                                    const fileRef = storageRef(storage, `complete-${item}.pdf`)
                                    const blob = await getBlob(fileRef)
                                    blobsToMerge.push(blob)
                                }
                                const merger = new PDFMerger();
                                for (const blob of blobsToMerge) {
                                    await merger.add(blob)
                                }
                                const mergedPdf = await merger.saveAsBlob();
                                const blobUrl = URL.createObjectURL(mergedPdf)
                                printJS(blobUrl)
                            } catch (e) {
                                if (e.code === "storage/object-not-found") {
                                    enqueueSnackbar("Error: PDF not created for one of the selected files.", {variant: "error"})
                                } else {
                                    enqueueSnackbar("Unknown Error while preparing files", {variant: "error"})
                                }
                                console.log(e)
                            }
                        }

                    }}
                            disabled={selectionModel.length < 2}
                            variant={"contained"}
                    >
                        Print+
                    </Button>
                    <Button onClick={async () => {
                        console.log(selectionModel)
                        if (selectionModel.length) {
                            try {
                                const toProcess = selectionModel
                                const blobsToMerge = []
                                const storage = getStorage()
                                for (const item of toProcess) {
                                    const fileRef = storageRef(storage, `complete-${item}.pdf`)
                                    const blob = await getBlob(fileRef)
                                    blobsToMerge.push(blob)
                                }
                                const merger = new PDFMerger();
                                for (const blob of blobsToMerge) {
                                    await merger.add(blob)
                                }
                                const mergedPdf = await merger.saveAsBlob();
                                const blobUrl = URL.createObjectURL(mergedPdf)
                                const link = document.createElement('a')
                                link.href = blobUrl
                                const date = new Date();
                                let day = date.getDate();
                                let month = date.getMonth() + 1;
                                let year = date.getFullYear();
                                let minute = date.getMinutes()
                                let hour = date.getHours()
                                link.download = `${selectionModel.length}-MergedFiles-${year}-${month}-${day}-${hour}h_${minute}m.pdf`
                                link.click()
                            } catch (e) {
                                if (e.code === "storage/object-not-found") {
                                    enqueueSnackbar("Error: PDF not created for one of the selected files.", {variant: "error"})
                                } else {
                                    enqueueSnackbar("Unknown Error while preparing files", {variant: "error"})
                                }
                                console.log(e)
                            }
                        }

                    }}
                            disabled={selectionModel.length < 2}
                            variant={"contained"}
                    >
                        Download+
                    </Button>
                    <Box>
                        <Button
                            onClick={()=> handleViewAllOrders()}
                            variant={"contained"}
                        >
                            View All Orders
                        </Button>
                    </Box>

                </Stack>

            </Box>
            <Box sx={{height: 600, width: "1480px", mx: "auto", my: "15px"}}>
                <DataGridPremium
                    apiRef={apiRef}
                    editMode="row"
                    rows={tableData}
                    columns={columns}
                    onRowClick={handleRowClick}
                    sortModel={sortModel}
                    onSortModelChange={(model) => setSortModel(model)}
                    checkboxSelection
                    selectionModel={selectionModel}
                    onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                    }}
                    density={"compact"}
                />
            </Box>
            {false ? <FetchCutHistory/>: <></>}


        </Box>
    )
}

