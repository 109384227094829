import {Box, Checkbox, FormControlLabel, FormGroup, Grid, TextField} from "@mui/material";
import * as PropTypes from "prop-types";

export function CommonForm(props) {
  return (
    <Box m={2}>
      <Grid container spacing={2} justifyContent="center" mb={2}>
        <Grid item>
          <TextField
            label="Client Name"
            size="small"
            InputLabelProps={{shrink: true,}}
            value={props.clientName}
            onChange={props.onChangeClientName}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Work Order"
            size="small"
            InputLabelProps={{shrink: true,}}
            value={props.workOrder}
            onChange={props.onChangeWorkOrder}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Address"
            size="small"
            InputLabelProps={{shrink: true,}}
            value={props.address}
            onChange={props.onChangeAddress}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Due Date"
            size="small"
            type="date"
            InputLabelProps={{shrink: true,}}
            value={props.dueDate}
            onChange={props.onChangeDueDate}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Model Name"
            size="small"
            InputLabelProps={{shrink: true,}}
            value={props.modelName}
            onChange={props.onChangeModelName}
          />
        </Grid>

        {
          (props.stairType === "interior"  || props.stairType === "garage") &&
          (
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  label="Flush:"
                  labelPlacement="start"
                  control={<Checkbox
                    checked={props.isFlush}
                    onChange={props.onChangeIsFlush}
                    disabled={props.isFlushProcessing}
                  />}
                />
              </FormGroup>
            </Grid>
          )
        }
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="In Production:"
              labelPlacement="start"
              control={<Checkbox
                checked={props.inProduction}
                onChange={props.onChangeInProduction}
              />}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="Built:"
              labelPlacement="start"
              control={<Checkbox
                checked={props.stairsBuilt}
                onChange={props.onChangeStairsBuilt}
              />}
            />
          </FormGroup>
        </Grid>
        <Grid item>
          <FormGroup>
            <FormControlLabel
              label="Shipped:"
              labelPlacement="start"
              control={<Checkbox
                checked={props.stairsShipped}
                onChange={props.onChangeStairsShipped}
              />}
            />
          </FormGroup>
        </Grid>

      </Grid>
    </Box>
  )
}

CommonForm.propTypes = {
  clientName: PropTypes.string,
  onChangeClientName: PropTypes.func,
  workOrder: PropTypes.string,
  onChangeWorkOrder: PropTypes.func,
  address: PropTypes.string,
  onChangeAddress: PropTypes.func,
  dueDate: PropTypes.string,
  onChangeDueDate: PropTypes.func,
  modelName: PropTypes.string,
  onChangeModelName: PropTypes.func,
  stairsBuilt: PropTypes.bool,
  onChangeStairsBuilt: PropTypes.func,
  inProduction: PropTypes.bool,
  onChangeInProduction: PropTypes.func,
  stairsShipped: PropTypes.bool,
  onChangeStairsShipped: PropTypes.func,
  isFlush: PropTypes.bool,
  onChangeIsFlush: PropTypes.func,
  stairType: PropTypes.string,
  isFlushProcessing: PropTypes.bool,
};
