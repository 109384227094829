import {useState} from "react";
import {createUserWithEmailAndPassword} from "firebase/auth";
import {auth} from "../firebase-config";
import {Box, Button, Container, TextField} from "@mui/material";

export function RegisterPage(){
  const [registerEmail, setRegisterEmail] = useState("")
  const [registerPassword, setRegisterPassword] = useState("")
  const register = async () => {
    try {
      await createUserWithEmailAndPassword(auth, registerEmail, registerPassword)
    } catch (error) {
      console.log(error.message)
    }
  }
  return (
  <div>
    <Container maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box textAlign="center">
          <h3>Stair Builder App Sign Up</h3>
        </Box>
        <Box sx={{ mt: 1 }}>
          <TextField
            label="Email"
            fullWidth
            onChange={(e)=>setRegisterEmail(e.target.value)}
          />
          <TextField
            label="Password"
            fullWidth
            type="password"
            onChange={(e)=>setRegisterPassword(e.target.value)}
            sx={{
              marginTop: 3,
            }}
          />
          <Button
            variant="contained"
            fullWidth
            onClick={register}
            sx={{
              marginTop: 3,
            }}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Container>
  </div>


)
}
